import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const Wrapper = styled(StyledContainer)`
  ${tw`pt-8 md:pt-15 pb-4 md:pb-10`}
`

export const Title = tw.h1`
  text-28 leading-1.28 tracking-10 md:text-35 text-center uppercase font-bold mb-1-2
`
