import React from "react"
import { graphql } from "gatsby"

import { Error as Page } from "../components/Error/Error"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageError {
      title
      content: _rawContent(resolveReferences: { maxDepth: 4 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {

  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("404_page")
  
  return <Page {...props} {...data} />
}
export default Component
