import React from "react"

import { withError } from "./withError"
import { Wrapper, Title } from "./ErrorStyles"
import { TextButtonsGrid } from "../TextButtonsGrid/TextButtonsGrid"

export const Error = withError(
  ({ title, content }): JSX.Element => (
    <Wrapper>
      <Title>{title}</Title>
      <TextButtonsGrid content={content} />
    </Wrapper>
  ),
)
